import { Box, Container, Link, Text } from '@chakra-ui/react';

const Contact = () => {
  return (
    <Container maxW='lg' py={8}>
      <Box textAlign='center' mb={6}>
        <Text fontSize='lg' color='gray.600' mb={2}>
          Hello {String.fromCharCode(38)} Welcome! Let's chat:
        </Text>
        <Text fontSize='lg' color='gray.700' mb={2}>
          <Link href='mailto:mhabte24@gmail.com' color='blue.400'>
            mhabte24@gmail.com
          </Link>
        </Text>
        <Text fontSize='lg' color='gray.700' mb={2}>
          <Link
            href='https://www.linkedin.com/in/meron-habtemariam/'
            color='blue.400'
            isExternal
          >
            LinkedIn
          </Link>
        </Text>
      </Box>
    </Container>
  );
};

export default Contact;
