import { Box, Link, List, ListItem, Text } from '@chakra-ui/react';

// todo: create place I can put all interfaces
export interface Award {
  name: string;
  text: string;
  href?: string;
}

interface AwardsProps {
  awards: Award[];
}

const Awards = ({ awards }: AwardsProps) => {
  return (
    <Box marginBottom='10px'>
      <Text fontWeight='bold' fontSize='lg'>
        Awards / Newsworthy Moments:
      </Text>
      <List>
        {awards.map((award: Award, i: number) => {
          return (
            <ListItem key={`${award.name}=${i}`}>
              - <i>{award.text}</i>
              <Link
                href={award.href}
                textDecoration='underline'
                textDecorationColor='teal.500'
                color='teal.500'
                isExternal
              >
                {award.name}
              </Link>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default Awards;
