import { Button, Container, Heading, Image, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const Error = () => {
  const navigate = useNavigate();

  return (
    <Container centerContent maxW='med' py={10} textAlign='center'>
      <Image src='/error.png' alt='Error Icon' boxSize='100px' mb={6} />

      <Heading as='h1' size='xl' mb={4} color='blue.500'>
        Whoopsie! Something Went Wrong 😢
      </Heading>

      <Text fontSize='lg' color='gray.600' mb={6}>
        Looks like you've taken a wrong turn. Let’s get you back on track!
      </Text>

      <Button colorScheme='blue' size='lg' onClick={() => navigate(-1)}>
        &larr; Go Back
      </Button>
    </Container>
  );
};

export default Error;
