import { Box, Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import Awards from '../../components/Awards';
import Collaborators from '../../components/Collaborators';
import Header from '../../components/Header';
// import Responsibilities from '../../components/Responsibilities';
import Videos from '../../components/Videos';

import Error from '../Error';
import { getBrandData } from './utils/getBrandData';

// todo: create place I can put all interfaces
interface Producer {
  name: string;
  role?: string;
  href?: string;
}

// todo: create place I can put all interfaces
interface Editorial {
  name: string;
  href?: string;
}

// todo: create place I can put all interfaces
interface Creative {
  name: string;
  href?: string;
}

// todo: create place I can put all interfaces
interface Color {
  name: string;
  company: string;
  href?: string;
}

const ShowcaseWork = () => {
  const { brandName, commercialName } = useParams<{
    brandName: string;
    commercialName?: string;
  }>();

  const retrievedBrand = brandName && getBrandData({ brandName });

  if (commercialName && retrievedBrand && retrievedBrand[commercialName]) {
    const {
      headerText,
      videos,
      shortVideoDescription,
      awardsNewsworthyMoments,
      // rolesAndResponsibilities,
      producers,
      productionCompany,
      animationCompany,
      editorials,
      director,
      editorialCompany,
      color,
      creatives,
      photographers,
    } = commercialName && retrievedBrand && retrievedBrand[commercialName];

    return (
      <Box maxWidth='1000px' px='10px' mx='auto' marginBottom='40px'>
        <Box>
          <Header headerText={headerText} />
          <Videos
            videos={videos}
            shortVideoDescription={shortVideoDescription}
          />
        </Box>
        <Flex direction={{ base: 'column', md: 'column', lg: 'row' }}>
          <Box mx={5} mb='10px' minWidth='150px' maxWidth='800px'>
            <Collaborators
              producers={producers}
              productionCompany={productionCompany}
              animationCompany={animationCompany}
              editorials={editorials}
              editorialCompany={editorialCompany}
              color={color}
              creatives={creatives}
              photographers={photographers}
              director={director}
            />
            {/* {rolesAndResponsibilities.length > 0 && (
              <Responsibilities responsibilities={rolesAndResponsibilities} />
            )} */}
            {awardsNewsworthyMoments.length > 0 && (
              <Awards awards={awardsNewsworthyMoments} />
            )}
          </Box>
        </Flex>
      </Box>
    );
  }

  return <Error />;
};

export default ShowcaseWork;
