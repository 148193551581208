import { Box, Link, List, ListItem, Text } from '@chakra-ui/react';
import {
  Color,
  Creative,
  Director,
  Editorial,
  Photographer,
  Producer,
  ProductionCompany,
} from '../interfaces';
interface CollaboratorsProps {
  producers: Producer[];
  productionCompany: ProductionCompany;
  animationCompany: ProductionCompany;
  editorials: Editorial[];
  editorialCompany: string;
  color: Color;
  creatives: Creative[];
  photographers: Photographer[];
  director: Director;
}

const Collaborators = ({
  producers,
  productionCompany,
  animationCompany,
  editorials,
  editorialCompany,
  color,
  creatives,
  director,
  photographers,
}: CollaboratorsProps) => {
  return (
    <Box marginBottom='30px'>
      <Text fontWeight='bold' fontSize='lg'>
        Dream Team:
      </Text>
      {creatives && (
        <List>
          <Text>Creatives:</Text>
          {creatives.map((creative: Creative, i: number) => {
            if (creative.href) {
              return (
                <ListItem key={`${creative}-${i}`}>
                  -{' '}
                  <Link
                    textDecoration='underline'
                    textDecorationColor='teal.500'
                    color='teal.500'
                    href={creative.href}
                    isExternal
                  >
                    {creative.name}
                  </Link>
                </ListItem>
              );
            } else {
              <ListItem key={`${creative}-${i}`}>
                -{' '}
                <Text as='span' color='black'>
                  {creative.name}
                </Text>
              </ListItem>;
            }
          })}
        </List>
      )}
      {producers && (
        <List>
          {producers.map((producer: Producer, i: number) => {
            if (producer.href) {
              return (
                <ListItem key={`${producer}-${i}`}>
                  {producer.role}:{' '}
                  <Link
                    textDecoration='underline'
                    textDecorationColor='teal.500'
                    color='teal.500'
                    href={producer.href}
                    isExternal
                  >
                    {producer.name}
                  </Link>
                </ListItem>
              );
            } else {
              return (
                <ListItem key={`${producer}-${i}`}>
                  {producer.role}:{' '}
                  <Text as='span' color='black'>
                    {producer.name}
                  </Text>
                </ListItem>
              );
            }
          })}
        </List>
      )}
      {productionCompany && (
        <List>
          <ListItem key={`${productionCompany}`}>
            {'Production Company'}:{' '}
            {productionCompany.href ? (
              <Link
                textDecoration='underline'
                textDecorationColor='teal.500'
                color='teal.500'
                href={productionCompany.href}
                isExternal
              >
                {productionCompany.name}
              </Link>
            ) : (
              <Text as='span' color='black'>
                {productionCompany.name}
              </Text>
            )}
          </ListItem>
        </List>
      )}
      {director && (
        <List>
          <ListItem key={`${director}`}>
            {'Director'}:{' '}
            {director.href ? (
              <Link
                textDecoration='underline'
                textDecorationColor='teal.500'
                color='teal.500'
                href={director.href}
                isExternal
              >
                {director.name}
              </Link>
            ) : (
              <Text as='span' color='black'>
                {director.name}
              </Text>
            )}
          </ListItem>
        </List>
      )}
      {animationCompany && (
        <List>
          <ListItem key={`${animationCompany}`}>
            {'Animation & Design Studio'}:{' '}
            {animationCompany.href ? (
              <Link
                textDecoration='underline'
                textDecorationColor='teal.500'
                color='teal.500'
                href={animationCompany.href}
                isExternal
              >
                {animationCompany.name}
              </Link>
            ) : (
              <Text as='span' color='black'>
                {animationCompany.name}
              </Text>
            )}
          </ListItem>
        </List>
      )}
      {editorialCompany && (
        <List>
          <Text>
            Editorial{editorials.length > 0 ? ',' : ': '} {editorialCompany}
            {editorials.length > 0 && ':'}
          </Text>
          {editorials.map((editorial: Editorial, i: number) => {
            if (editorial.href) {
              return (
                <ListItem key={`${editorial}-${i}`}>
                  -{' '}
                  <Link
                    textDecoration='underline'
                    textDecorationColor='teal.500'
                    color='teal.500'
                    href={editorial.href}
                    isExternal
                  >
                    {editorial.name}
                  </Link>
                </ListItem>
              );
            } else {
              return (
                <ListItem key={`${editorial}-${i}`}>
                  -{' '}
                  <Text as='span' color='black'>
                    {editorial.name}
                  </Text>
                </ListItem>
              );
            }
          })}
        </List>
      )}
      {color && (
        <List>
          <Text>
            Color, {color.company}:{' '}
            <Link
              textDecoration='underline'
              textDecorationColor='teal.500'
              color='teal.500'
              isExternal
              href={color.href}
            >
              {color.name}
            </Link>
          </Text>
        </List>
      )}
      {photographers && (
        <List>
          <Text>Photographers:</Text>
          {photographers.map((photographer: Photographer, i: number) => {
            if (photographer.href) {
              return (
                <ListItem key={`${photographer}-${i}`}>
                  -{' '}
                  <Link
                    textDecoration='underline'
                    textDecorationColor='teal.500'
                    color='teal.500'
                    href={photographer.href}
                    isExternal
                  >
                    {photographer.name}
                  </Link>
                </ListItem>
              );
            } else {
              return (
                <ListItem key={`${photographer}-${i}`}>
                  -{' '}
                  <Text as='span' color='black'>
                    {photographer.name}
                  </Text>
                </ListItem>
              );
            }
          })}
        </List>
      )}
    </Box>
  );
};

export default Collaborators;
