import { AspectRatio, Box, Center, Text } from '@chakra-ui/react';

// todo: create place I can put all interfaces
interface Video {
  videoSrc: string;
  videoImg: string;
  shortVideoDescription: string;
}

interface VideoProps {
  videos: Video[];
  shortVideoDescription: string;
}

const Videos = ({ videos, shortVideoDescription }: VideoProps) => {
  return (
    <Box>
      <Box marginBottom={5}>
        <Text as='i' fontSize='md'>
          {shortVideoDescription}
        </Text>
      </Box>
      {videos.map((video, key) => {
        return (
          <Box key={`video-${key}`} maxWidth='1000px' margin='auto' mb='20px'>
            <Center>
              <AspectRatio ratio={16 / 8} width='1000px'>
                <iframe
                  className='ndfHFb-c4YZDc-Wrql6b'
                  src={video.videoSrc}
                  allow='autoplay'
                ></iframe>
              </AspectRatio>
            </Center>
          </Box>
        );
      })}
    </Box>
  );
};

export default Videos;
