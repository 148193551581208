// dicks-sporting-goods
//  - /athlete-stories
//  - /sports-change-lives
// - /2024-paris-olympics
const dicksSportingGoodsData = {
  '2024-paris-olympics': {
    headerText: `Dick's Sporting Goods - 2024 Paris Olympics`,
    videos: [
      {
        videoSrc:
          'https://www.youtube.com/embed/uVSNWBPrKGM?si=pEFoQyBxnjGtutR0',
        videoImg: null,
      },
    ],
    shortVideoDescription: `During a time when most people are watching athletes compete on the world stage, DICK’S Sporting Goods wanted to find a way to recognize the big moments in sports that can happen anywhere. For the next chapter of our Sports Change Lives platform, we worked with the brand to create a UGC-focused campaign that gives center stage to the triumphs, wins, and celebrations that feel life-changing to everyday athletes.`,
    rolesAndResponsibilities: [],
    awardsNewsworthyMoments: [
      {
        name: `Big Moments Every Day: Dick's Sporting Goods`,
        text: `Clio Bronze Winner - `,
        href: 'https://clios.com/winners-gallery/details/37236',
      },
      {
        name: `DICK’S Sporting Goods Champions Every Day Athletes in Continuation of 'Sports Change Lives`,
        text: `LBB Online: `,
        href: 'https://lbbonline.com/news/dicks-sporting-goods-champions-every-day-athletes-in-continuation-of-sports-change-lives',
      },
      {
        name: `DICK'S Sporting Goods hits big with 'small moments' in sports during Paris Olympics ad`,
        text: `USA Today: `,
        href: 'https://admeter.usatoday.com/2024/07/26/dicks-sporting-goods-paris-olympics-commercial-opening-ceremony/',
      },
    ],
    producers: [
      {
        name: 'Andrea Rodriguez',
        role: 'Sr. Producer',
        href: '',
      },
    ],
    editorialCompany: 'Final Cut',
    editorials: [],
    creatives: [
      {
        name: 'Mike Lee',
        href: 'https://bookofmikelee.com/DICK-S-Paris-Olympics',
      },
      {
        name: 'Thea Ryan',
        href: 'https://thea-ryan.com/DICK-s-Sporting-Goods-x-Olympics',
      },
    ],
  },
  'sports-change-lives': {
    headerText: `Dick's Sporting Goods - Sports Change Lives`,
    videos: [
      {
        videoSrc:
          'https://www.youtube.com/embed/6j0hKarvGK4?si=k0PeK5pfscqn9DYY',
        videoImg: null,
      },
    ],
    shortVideoDescription: `With Sports Change Lives we created a brand idea powerful enough to connect with any athlete and expansive enough to cover all the ways sports create positive change in people, teams, families, and communities. The anthem focused on a wide range of true-to-life changes everyday athletes experience, combined with real, sourced-footage moments that showcased the true emotional power of sports.`,
    rolesAndResponsibilities: [
      {
        text: 'placeholder, placeholder , placeholder, placeholder, placeholder, placeholder, placeholder',
      },
      {
        text: 'placeholder, placeholder , placeholder, placeholder, placeholder, placeholder, placeholder',
      },
      {
        text: 'placeholder, placeholder , placeholder, placeholder, placeholder, placeholder, placeholder',
      },
    ],
    awardsNewsworthyMoments: [],
    producers: [
      {
        name: 'Jourdan Valdes',
        role: 'Sr. Producer',
        href: 'https://www.jourdanvaldes.com/',
      },
      {
        name: 'Rachael Hill',
        role: 'Producer',
        href: '',
      },
    ],
    productionCompany: {
      name: 'SANCTUARY',
      href: '',
    },
    director: {
      name: 'Cole Webley',
      href: '',
    },
    creatives: [
      {
        name: 'Tanner McColl',
        href: 'https://tannermccoll.com/',
      },
      {
        name: 'Jacob Pankey',
        href: 'https://www.pankeydotgov.com/',
      },
      {
        name: 'Albert Song',
        href: 'https://www.albertsong.work/',
      },
      {
        name: 'Whitney Schneden',
        href: '',
      },
    ],
  },
  'athlete-stories': {
    headerText: `Dick's Sporting Goods - Athlete Stories`,
    videos: [
      {
        videoSrc:
          'https://www.youtube.com/embed/tQsjf3EufW4?si=Qe9jkNz7nS2QikzE',
        videoImg: null,
      },
      {
        videoSrc:
          'https://www.youtube.com/embed/k5rQY9SVDUg?si=AOFT88uynRJhUCTu',
        videoImg: null,
      },
      {
        videoSrc:
          'https://www.youtube.com/embed/vLuyeB8yrFE?si=6xcSlfZXcaMVofKO',
        videoImg: null,
      },
    ],
    shortVideoDescription: `“This series takes an authentic, personal look at how sports have changed the lives of some of the world’s top athletes, from cultivating community to unlocking confidence. As viewers watch these Nike and Jordan athlete stories, we hope they are reminded of the positive impact sports can have on anyone's life and feel inspired to engage in sports."`,
    rolesAndResponsibilities: [
      {
        text: 'placeholder, placeholder , placeholder, placeholder, placeholder, placeholder, placeholder',
      },
      {
        text: 'placeholder, placeholder , placeholder, placeholder, placeholder, placeholder, placeholder',
      },
      {
        text: 'placeholder, placeholder , placeholder, placeholder, placeholder, placeholder, placeholder',
      },
    ],
    awardsNewsworthyMoments: [
      {
        name: 'Branded Content - Athlete Storytelling',
        text: `Clio Bronze Winner - `,
        href: 'https://clios.com/winners-gallery/details/7857',
      },
      {
        name: 'Branded Content - Diversity Storytelling',
        text: `Clio Bronze Winner - `,
        href: 'https://clios.com/winners-gallery/details/7860',
      },
    ],
    producers: [
      {
        name: 'Jourdan Valdes',
        role: 'Sr. Producer',
        href: 'https://www.jourdanvaldes.com/',
      },
      {
        name: 'Sean Riojas',
        role: 'Producer',
        href: 'https://seanriojas.squarespace.com/',
      },
    ],
    productionCompany: {
      name: 'CHELSEA',
      href: '',
    },
    director: {
      name: 'Nadia Hallgren',
      href: '',
    },
    creatives: [
      {
        name: 'Tanner McColl',
        href: 'https://tannermccoll.com/',
      },
      {
        name: 'Jacob Pankey',
        href: 'https://www.pankeydotgov.com/',
      },
      {
        name: 'Albert Song',
        href: 'https://www.albertsong.work/',
      },
      {
        name: 'Whitney Schneden',
        href: '',
      },
    ],
  },
};

export default dicksSportingGoodsData;
