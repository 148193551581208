import { ChakraProvider } from '@chakra-ui/react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import theme from './theme';

import Contact from './pages/Contact';
import Error from './pages/Error';
import Home from './pages/Home';
// import PageNotFound from './pages/PageNotFound';
import ShowcaseWork from './pages/Showcase/ShowcaseWork';

localStorage.removeItem('chakra-ui-color-mode');

export const App = () => (
  <ChakraProvider theme={theme}>
    <Navbar />
    <Routes>
      <Route path='/work' element={<Home />} errorElement={<Error />} />
      <Route path='/contact' element={<Contact />} errorElement={<Error />} />
      <Route
        path='/:brandName/:commercialName'
        element={<ShowcaseWork />}
        errorElement={<Error />}
      />
      <Route path='*' element={<Navigate to='/work' />} />
    </Routes>
  </ChakraProvider>
);
