// nbc-today-show
//  - /everyday-needs-today
const nbcTodayShowData = {
  'everyday-needs-today': {
    headerText: `NBC Today Show - Every Day Needs TODAY`,
    videos: [
      {
        videoSrc:
          'https://player.vimeo.com/video/750075055?h=79884875ff&app_id=122963',
        videoImg: null,
      },
      {
        videoSrc:
          'https://player.vimeo.com/video/750083195?h=c314aacbb4&app_id=122963',
        videoImg: null,
      },
      {
        videoSrc:
          'https://player.vimeo.com/video/750100122?h=6b33eaf27d&app_id=122963',
        videoImg: null,
      },
    ],
    shortVideoDescription: `For 70 years, millions of Americans have come to rely on TODAY as an essential part of their morning routines, where the anchors feel like friends and family. Our new campaign explored what every day needs - from larger truths to smaller moments - and how TODAY is there with you for it all.`,
    rolesAndResponsibilities: [
      {
        text: 'Owned finishing and delivery',
      },
    ],
    awardsNewsworthyMoments: [],
    producers: [
      {
        name: 'Julie Lefton',
        role: 'Sr. Producer',
        href: '',
      },
    ],
    productionCompany: {
      name: 'm ss ng p eces',
      href: '',
    },
    director: {
      name: 'Michael Marantz',
      href: '',
    },
    creatives: [
      {
        name: 'Erica Canup',
        href: 'http://www.ericacanup.com/',
      },
      {
        name: 'Christina Nordin',
        href: 'https://christinanordin.com/',
      },
    ],
  },
};

export default nbcTodayShowData;
