import bedBathBeyondData from './bedBathBeyondData';
import dicksSportingGoodsData from './dicksSportingGoods';
import googleData from './google';
import nbcTodayShowData from './nbcTodayShow';

interface getBrandDataProps {
  brandName: string;
}

export const getBrandData = ({ brandName }: getBrandDataProps): any => {
  if (brandName === 'bed-bath-beyond') {
    return bedBathBeyondData;
  } else if (brandName === 'dicks-sporting-goods') {
    return dicksSportingGoodsData;
  } else if (brandName === 'nbc-today-show') {
    return nbcTodayShowData;
  } else if (brandName === 'google') {
    return googleData;
  }
};
