// google
//  - /google-assistant-privacy
//  - /google-nest-pets
//  - /google-pixel-pride
// - /google-chromebook
const googleData = {
  'google-chromebook': {
    headerText: `Google Chromebook - Do Less to Do More`,
    videos: [
      {
        videoSrc:
          'https://www.youtube.com/embed/pss_sOX09Ag?si=p6rG-Sxnkgyx7R7i',
        videoImg: null,
      },
    ],
    shortVideoDescription: `Lately, the world seems to be asking us to do more and more and more. But what if you could do less while still getting more done? With the launch of our creative platform for Chromebook Plus with Gemini, we wanted to show the world they could now ‘do less to do more’ with one click.`,
    rolesAndResponsibilities: [],
    awardsNewsworthyMoments: [],
    producers: [
      {
        name: 'Danny Ryan',
        role: 'Producer',
        href: 'https://www.danny-ryan.com/',
      },
    ],
    productionCompany: {
      name: 'ManvsMachine',
      href: '',
    },
    creatives: [
      {
        name: 'Devin Altman',
        href: 'https://www.d-altman.com/',
      },
      {
        name: 'Ethan Pestano',
        href: 'https://www.ethanpestano.com/',
      },
      {
        name: 'Matthew Warren',
        href: 'https://www.mwarrencreate.com/',
      },
    ],
  },
  'google-assistant-privacy': {
    headerText: `Google Assistant - Privacy Film "Look & Talk"`,
    videos: [
      {
        videoSrc:
          'https://www.youtube.com/embed/LTBOaCHEvbU?si=qbnNLYr6_LTx9J0K',
        videoImg: null,
      },
    ],
    shortVideoDescription: `No one wants to say “Hey Google” every time they need a little help, so here’s an informational animation to break you of your terrible query habits.`,
    rolesAndResponsibilities: [],
    awardsNewsworthyMoments: [],
    animationCompany: {
      name: 'Oddfellows',
      href: 'https://oddfellows.tv/',
    },
    creatives: [
      {
        name: 'Joe Jones',
        href: 'https://joejones.work/',
      },
      {
        name: 'Cory McCollum',
        href: 'https://www.corywriter.com/',
      },
    ],
  },
  'google-nest-pets': {
    headerText: `Google Nest - #CaughtOnNestCam`,
    videos: [
      {
        videoSrc:
          'https://www.youtube.com/embed/7dlBI-UNA68?si=ofhRQHOMaMDG1Vs0',
        videoImg: null,
      },
      {
        videoSrc:
          'https://www.youtube.com/embed/j0t2ri2EFxc?si=uqjLk6IWk9q_EpV-',
        videoImg: null,
      },
      {
        videoSrc:
          'https://www.youtube.com/embed/Leq4JUCMqxA?si=oejGNvorSePRSk72',
        videoImg: null,
      },
      {
        videoSrc:
          'https://www.youtube.com/embed/wmO3fiyVs6o?si=3kpg1FPIoZ7hPjle',
        videoImg: null,
      },
      {
        videoSrc:
          'https://www.youtube.com/embed/-ouIHjlXpDo?si=e8y-Aes7jc6r-g89',
        videoImg: null,
      },
    ],
    shortVideoDescription: `To launch Google Nest’s new security cameras and doorbell, we wanted to show how these devices help you keep an eye on your home even when you’re away. And what better way to do that than through the lens of our furry family members at home? To launch this campaign, we used real Nest cam footage and the voices of some amazing influencers to bring to life a world of talking animals saying and doing the most outrageous things while we’re gone.`,
    rolesAndResponsibilities: [
      {
        text: 'VO castings and records by ensuring scripts were accurate, formatted, and sent to talent and audio houses',
      },
      {
        text: 'Coordinated with talent, creatives, and audio houses to schedule records.',
      },
    ],
    awardsNewsworthyMoments: [
      {
        name: `Google Nest's Charming Ads Show What Pets Do Home Alone`,
        text: ` Muse by Clios: `,
        href: 'https://musebyclios.com/advertising/google-nests-charming-ads-show-what-pets-do-home-alone',
      },
    ],
    producers: [
      {
        name: 'Julie Lefton',
        role: 'Sr. Producer',
        href: '',
      },
    ],
    creatives: [
      {
        name: 'Andrew Caturano',
        href: 'https://acaturano.com/',
      },
      {
        name: 'Scott Horlbeck',
        href: 'https://scotthorlbeck.me',
      },
    ],
  },
  'google-pixel-pride': {
    headerText: `Google Pixel - Pride`,
    videos: [
      {
        videoSrc:
          'https://www.youtube.com/embed/ohxDSfPpsHE?si=5VQzjAXmQqDpsk5n',
        videoImg: null,
      },
    ],
    shortVideoDescription: `As part of a broader Google effort to support the LGBTQ+ community through the pandemic, we helped Google Pixel amplify the enduring, vibrant legacy of inclusive spaces and inspire inclusivity at scale.`,
    rolesAndResponsibilities: [
      {
        text: 'Assisted my producer by being the in-person POC during the NYC shoot and the virtual POC during the ATL shoot while she managed the Chicago & San Fran Shoots',
      },
      {
        text: 'Due to the amount of images that needed clearance, I assisted Business Affairs by creating and maintaining the image clearance tracker and double checked contracts as needed',
      },
    ],
    awardsNewsworthyMoments: [
      {
        name: `Google Pixel's Pride Ad Wants You to Spread the Word About Queer-Friendly Spaces`,
        text: `Adweek: `,
        href: 'https://www.adweek.com/brand-marketing/google-pixels-pride-ad-wants-you-to-spread-the-word-about-queer-friendly-spaces/',
      },
      {
        name: 'The Richmond Show brings together creatives from Richmond and surrounding areas to celebrate their exceptional talent and hard work.',
        text: `Advertising Club of Richmond: `,
        href: 'https://www.richmondadclub.com/richmond-show/winners/celebrate-pride-in-belonging-with-pixel',
      },
    ],
    producers: [
      {
        name: 'Mel Calabro',
        role: 'Producer',
        href: 'https://www.melcalabro.com/',
      },
    ],
    creatives: [
      {
        name: 'Sam Jolley ',
        href: 'http://samanthajolley.com/',
      },
      {
        name: 'Amber Brissette',
        href: 'http://www.amberwroteit.com',
      },
    ],
    editorialCompany: 'XYZ',
    editorials: [
      {
        name: 'Dallas Simms',
      },
    ],
    photographers: [
      {
        name: 'Cindy Elizabeth (ATX)',
        href: '',
      },
      {
        name: 'James Hosking (CHI, SF)',
        href: '',
      },
      {
        name: 'Meghan Marin (NYC, ATL)',
        href: '',
      },
    ],
  },
};

export default googleData;
