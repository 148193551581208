import { Heading } from '@chakra-ui/react';

interface HeaderProps {
  headerText: string;
}
const Header = ({ headerText }: HeaderProps) => {
  return (
    <Heading textAlign='center' mb={5}>
      {headerText}
    </Heading>
  );
};

export default Header;
