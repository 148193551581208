import {
  Box,
  Image,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';

interface AdvertisementCardProps {
  href: string;
  imageSrc: string;
  altText: string;
  description: string;
}

const AdvertisementCard = ({
  href,
  imageSrc,
  altText,
  description,
}: AdvertisementCardProps) => (
  <Box display='flex' flexDirection='column' alignItems='center'>
    <LinkBox
      bg='white'
      height='300px'
      width='100%'
      maxWidth='500px'
      display='flex'
      justifyContent='center'
      alignItems='center'
      fontSize='18px'
      transition='font-size 0.5s ease'
      _hover={{
        opacity: 0.9,
        fontSize: '22px',
      }}
    >
      <LinkOverlay href={href} />
      {imageSrc && (
        <Image
          src={imageSrc}
          alt={altText}
          objectFit='cover'
          objectPosition='top'
          width='100%'
          height='100%'
        />
      )}
    </LinkBox>
    <Text textAlign='center' mt='2'>
      {description}
    </Text>
  </Box>
);

const Home = () => {
  const ads = [
    {
      href: '/google/google-chromebook',
      imageSrc: '/google-chromebook.png',
      altText: 'Google Chromebook Do Less To Do More Commercial',
      description: 'Google Chromebook - Do Less To Do More',
    },
    {
      href: '/dicks-sporting-goods/2024-paris-olympics',
      imageSrc: '/paris-olympics.png',
      altText: "Dick's Sporting Goods 2024 Paris Olympics Commercial",
      description: "Dick's Sporting Goods - 2024 Paris Olympics",
    },
    {
      href: '/bed-bath-beyond/we-are-back',
      imageSrc: '/bedbathbeyond.png',
      altText: 'Bed, Bath, & Beyond Commercial Image',
      description: 'Bed, Bath, & Beyond - More Beyond Than Ever',
    },
    {
      href: '/dicks-sporting-goods/athlete-stories',
      imageSrc: '/aja-wilson.png',
      altText: `Dick's Sporting Goods Athlete Stories Commercial Image`,
      description: "Dick's Sporting Goods - Athlete Stories",
    },
    {
      href: '/dicks-sporting-goods/sports-change-lives',
      imageSrc: '/sportschangelives.png',
      altText: 'Dicks Sporting Goods Sports Change Lives Commercial Image',
      description: "Dick's Sporting Goods - Sports Change Lives",
    },
    {
      href: '/nbc-today-show/everyday-needs-today',
      imageSrc: '/nbc-today-show.png',
      altText: 'NBC Today Show Commercial Image',
      description: 'NBC Today Show - Every Day Needs TODAY',
    },
    {
      href: '/google/google-assistant-privacy',
      imageSrc: '/google-assistant.png',
      altText: 'Google Assistant Commercial Image',
      description: 'Google Assistant - Privacy Film "Look & Talk"',
    },
    {
      href: '/google/google-nest-pets',
      imageSrc: '/google-nest-pets.png',
      altText: 'Google Nest Pets Commercial Image',
      description: 'Google Nest - #CaughtOnNestCam',
    },
    {
      href: '/google/google-pixel-pride',
      imageSrc: '/google-pixel-pride.png',
      altText: 'Google Pixel Pride Commercial Image',
      description: 'Google Pixel - Pride',
    },
  ];

  return (
    <Box>
      <SimpleGrid
        columns={{ base: 1, md: 1, lg: 2 }}
        spacing='4'
        margin='20px auto'
        width='90%'
        maxWidth='1200px'
      >
        {ads.map((ad, index) => (
          <AdvertisementCard key={index} {...ad} />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Home;
