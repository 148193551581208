// bed-bath-beyond
//  - /we-are-back
const bedBathBeyondData = {
  'we-are-back': {
    headerText: `Bed Bath & Beyond - More Beyond Than Ever`,
    videos: [
      {
        videoSrc:
          'https://www.youtube.com/embed/xTC7xS60SOE?si=d98eE-pMiY9G-2N9',
        videoImg: null,
      },
    ],
    shortVideoDescription: `To help relaunch Bed Bath & Beyond to the world, we tapped into the excitement and nostalgia customers feel for the brand - most notably, in the form of its famous 20${String.fromCharCode(
      37
    )} off coupon. From Times Square to taxi tops to TV screens, we celebrate the return of an iconic brand that's now more beyond than ever.`,
    rolesAndResponsibilities: [
      // {
      //   text: 'Owned digital |+|OOH@@https://www.instagram.com/p/CzmYf1fvhvy/',
      // },
      // {
      //   text: 'On set POC for the facetime moment',
      // },
      // {
      //   text: 'Coordinated casting',
      // },
    ],
    awardsNewsworthyMoments: [
      {
        name: `Bed Bath ${String.fromCharCode(
          38
        )} Beyond Markets Is Comeback as 'As Icon Returns' in First Work From Arts ${String.fromCharCode(
          38
        )} Letters `,
        text: `Adweek: `,
        href: 'https://www.adweek.com/agencies/bed-bath-beyond-arts-letters-icon-returns/',
      },
      {
        name: `Bed Bath ${String.fromCharCode(
          38
        )} Beyond Returns in Campaign from Arts ${String.fromCharCode(
          38
        )} Letters`,
        text: `LLB Online: `,
        href: 'https://lbbonline.com/news/bed-bath-beyond-returns-in-campaign-from-arts-letters',
      },
    ],
    producers: [
      {
        name: 'Jourdan Valdes',
        role: 'Sr. Producer',
        href: 'https://www.jourdanvaldes.com/',
      },
    ],
    productionCompany: {
      name: 'Spang TV',
      href: '',
    },
    director: {
      name: 'Geoff Castillo',
      href: '',
    },
    editorialCompany: 'XYZ',
    editorials: [
      {
        name: 'Ben Surber',
        href: 'https://bensurberhasawebsite.com/contact-1',
      },
      {
        name: 'Keiyana Bryant',
        href: 'https://www.keiyanabryant.com/',
      },
    ],
    color: {
      name: 'Matt Rosenblum',
      company: 'XYZ',
      href: 'http://www.mattrosenblum.com/',
    },
    creatives: [
      {
        name: 'Alana Robinson',
        href: 'https://www.alanarobinson.com/',
      },
      {
        name: 'Albert Song',
        href: 'https://www.albertsong.work/',
      },
      {
        name: 'Whitney Schneden',
        href: '',
      },
    ],
  },
};

export default bedBathBeyondData;
